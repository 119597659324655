import React from 'react';
import StarRatings from "react-star-ratings";

interface IProps {
  score?: string;
  color?: string;
  dimention?: string;
  spacing?: string;
}

const StarRating: React.FC<IProps> = ({score = '0', color = '#FFAA00', dimention = '25px', spacing = '1px', ...rest}: IProps) => {
  return (
    <StarRatings
      starRatedColor={color}
      starDimension={dimention}
      starSpacing={spacing}
      rating={score - 0}
      {...rest} />
  );
};

export default StarRating;
