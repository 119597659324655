import Chart from 'chart.js/auto';

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('canvas.chart').forEach(elem => {
    const data = JSON.parse(elem.dataset.value);
    console.log(data);
    const opts = {
      type: 'radar',
      data,
      options: {
        layout: {
          padding: {
            left: 50,
            right: 50
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scale: {
          r: {
            suggestedMin: 0,
            suggestedMax: 5,
          },
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            display: false,
          },
        },
      }
    };
    new Chart(elem, opts);
  });
});
