(() => {
  const showSnackBar = node => node.classList.add('show');
  const hiddenSnackBar = node => setTimeout(() => node.classList.remove('show'), 5000);

  document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll('.snackbar').forEach(node => {
      showSnackBar(node);
      hiddenSnackBar(node);
    });
  });
})();
