import React, {useCallback, useEffect, useState} from 'react';

interface IProps {
  sec: string;
  [key: string]: string;
}

const CountdownTimer: React.FC<IProps> = ({sec, ...rest}: IProps) => {
  const [second, setSec] = useState<number>(sec <= 0 ? 0 : (sec - 0));

  const tick = useCallback((): void =>
    setSec((old: number): number => ((old <= 0) ? 0 : old - 1)), [setSec]);

  useEffect(() => {
    const timerId = setInterval((): void => tick(), 1000);
    return () => clearInterval(timerId);
  }, []);

  return (
    <div {...rest}>
      {Math.floor(second / (24 * 60 * 60))}日
      {Math.floor((second / (60 * 60)) % 24)}時
      {Math.floor((second / 60) % 60)}分
      {Math.floor(second % 60)}秒
    </div>
  );
};

export default React.memo(CountdownTimer);
