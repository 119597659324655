// インビの引継ぎ
document.addEventListener('DOMContentLoaded', () => {
    const current_url = new URL(location.href);
    const params = new URLSearchParams(location.search);
    if (!params.has('inv')) {
        return;
    }
    document.querySelectorAll('a').forEach(elem => {
        const url = new URL(elem.getAttribute('href'), location);
        if (current_url.hostname !== url.hostname) {
            return;
        }
        url.searchParams.set('inv', params.get('inv'));
        elem.setAttribute('href', `${url.pathname}${url.search}${url.hash}`);
    });
});
