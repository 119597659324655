import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {Button, Card, Form} from 'react-bootstrap';
import {faTimes, faTrash} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Checkbox from './../../components/Checkbox';
import SearchOrCreateQuestionField from './SearchOrCreateQuestionField';
import styles from './QuestionSelect.module.scss';

interface Question {
  id: number;
  name: string;
}

interface IProps {
  list: Array<Question>;
  name: string;
  defaultValue: string;
}

const QuestionsField: React.FC<IProps> = (props: IProps) => {
  const list: Array<Question> = useMemo(() => JSON.parse(props.list || '[]'), [props.list]);
  const defaultValue: Array<Question> = useMemo(() => JSON.parse(props.defaultValue || '[]'), [props.defaultValue]);
  const orderBy = {field: 'priority', direction: 'asc'};
  const query = {isDefault: true};

  const [selected, setSelected] = useState<Array<Question>>(defaultValue || []);

  /**
     * 選択肢の追加
     * @param question - 質問オブジェクト
     * @returns void
     */
  const injectSelected = (question: Question): void =>
    setSelected((old: Array<Question>): Array<Question> => [...new Set([...old, question])]);

  /**
     * 選択肢の削除
     * @param id - 質問ID
     * @returns void
     */
  const rejectSelected = (id: string): void =>
    setSelected((old: Array<Question>): Array<Question> => [...old.filter((q: Question): boolean => q.id !== id)]);

  /**
     * 質問を選択したときのハンドラー
     * @param e - ChangeEvent
     * @param question - 質問オブジェクト
     * @returns void
     */
  const handleOnChangeSelected = (e: React.ChangeEvent<HTMLInputElement>, question: Question): void =>
    e.target.checked ? injectSelected(question) : rejectSelected(question.id);

  /**
     * ハンドラーの作成
     * @param question - 質問オブジェクト
     * @returns 質問を選択したときのハンドラー
     */
  const createHandleOnChangeQuestions = (question: Question): (e: React.ChangeEvent<HTMLInputElement>) => void =>
    (e: React.ChangeEvent<HTMLInputElement>): void => handleOnChangeSelected(e, question);

  /**
     * 検索or新規作成のハンドラー
     * @param question - 質問オブジェクト
     * @returns void
     */
  const handleChangeSearchOrCreate = useCallback((question: Question): void => injectSelected(question), []);

  /**
     * 実際に聞かれた質問リストの削除
     * reactはe.currentTargetが無いのでe.target.valueを代用するが、
     * これだとbuttonの中にElementがあるとtargetがそいつになりうるので仕方なく回避策
     * @param question - 質問オブジェクト
     * @returns クリックされた際のイベントハンドラー
     */
  const createHandleOnClickDelete = (question: Question): (e: React.MouseEvent<HTMLInputElement>) => void =>
    (e: React.MouseEvent<HTMLInputElement>): void => rejectSelected(question.id);

  return (
    <>
      <h4 className={styles.title}>①まずは頻出の質問にチェックしよう</h4>

      <Form.Group>
        {list.length > 0 &&
                    <ul className="mb-5 ps-0">
                      {list.map(question => 
                        <li key={question.id} className={styles.listItem}>
                          <Checkbox
                            name="question"
                            onChange={createHandleOnChangeQuestions(question)}
                            label={question.name}
                            checked={selected.some((q: Question): boolean => q.id === question.id)}
                            value={question.id}
                            id={`question-check-${question.id}`}/>
                        </li>
                      )}
                    </ul>
        }
      </Form.Group>

      <h4 className={styles.title}>②上記以外の質問は、キーワード検索してリストに追加しよう</h4>

      <p>※※検索候補にない場合は、質問文を入力して、リストに追加をお願いします。</p>

      <Form.Group className="mb-5">
        <SearchOrCreateQuestionField onChange={handleChangeSearchOrCreate} />
      </Form.Group>


      <Card className={styles.card}>
        <Card.Header className={styles.cardHeader}>
          <div className={styles.cardHeaderTitle}>実際に聞かれた質問リスト</div>
        </Card.Header>
        <Card.Body>
          {selected.length === 0 && <small className="annotation">実際に聞かれた質問を選択してください。</small>}
          {selected.length !== 0 && (
            <ul className="row ps-0">
              {selected.map(question => (
                <li key={question.id} className={`${styles.selected} col-12 col-md-6`}>
                  <div className="me-1">{question.name}</div>
                  <Button type="button"
                    className="ms-auto"
                    variant="outline-light"
                    size="sm"
                    value={question.id}
                    onClick={createHandleOnClickDelete(question)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </Button>
                  <input type="hidden" name={props.name} value={question.id} />
                </li>
              ))}
            </ul>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default React.memo(QuestionsField);
