window.addEventListener('DOMContentLoaded', async () => {
  const selects = document.querySelectorAll('select[name="investor[id]"]');

  const disabledOthers = e => {
    selects.forEach(elem => {
      if (elem === e.currentTarget) return;
      elem.disabled = !!e.currentTarget.value;
    });
  };

  selects.forEach(elem => {
    elem.addEventListener('change', disabledOthers);
    elem.dispatchEvent(new Event('change', {bubbles: true, cancelable: true}));
  });
});

window.addEventListener('DOMContentLoaded', function(){

  const passwordIcon = document.getElementById("password");
  const inputPass = document.getElementById("input-password");

  passwordIcon.addEventListener("click", ()=>{

    if(inputPass.type === 'password' ) {
      inputPass.type = 'text';
      passwordIcon.className = "regi-form-icon icon icon-eye";
    } else {
      inputPass.type = 'password';
      passwordIcon.className = "regi-form-icon icon icon-eye-slash";
    }
  });

});
