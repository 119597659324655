import React, { useEffect, useState } from 'react';
import styles from './Checkbox.module.scss';
import CheckSquare from './../graphics/CheckSquare';
import Square from './../graphics/Square';

export default ({
  label,
  name = undefined,
  value = undefined,
  className = undefined,
  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {},
  checked = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(checked);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>): void =>
    setIsChecked((old: boolean): boolean => {
      e.target.checked = !old;
      onChange(e);
      return e.target.checked;
    });

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return <label {...props} className={`${styles.container} ${className}`}>
    <input type="checkbox"
      className={styles.checkbox}
      checked={props.checked}
      name={name}
      value={value}
      onChange={handleOnChange} />
    <span className={styles.svg}>
      {isChecked ? <CheckSquare /> : <Square />}
    </span>
    <span className={styles.label}>{label}</span>
  </label>;
};
