import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import axios from "./../../../http/axios";
import Autosuggest from './../../components/Autosuggest';
import styles from './SearchOrCreateQuestionField.module.scss';

interface Question {
  id: number;
  name: string;
}

interface IProps {
    onChange: (question: Question) => void;
}

const SearchOrCreateQuestionField: React.FC<IProps> = (props: IProps) => {
  const [canSend, setCanSend] = useState<boolean>(false);
  const [candidate, setCandidate] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Array<Question>>([]);

  useEffect(() => setCanSend(candidate.length !== 0), [candidate]);

  /**
     * TOP15以外で聞かれた質問の検索ワード
     * @param e - ChangeEvent
     * @param { newVal } - react-autosuggestで生成された新たな値
     * @returns void
     */
  const handleOnChangeCandidate = (e: React.ChangeEvent<HTMLInputElement>, { newValue }): void => setCandidate(newValue);

  /**
     * 候補を取得
     * @returns Promise<void>
     */
  const fetchCandidates = async (name: string): Promise<void> => {
    try {
      const res = await axios.get('/questions', {params: {name, format: 'json'}});
      setSuggestions(res.data || []);
    } catch (err) {
      console.error(err);
      setSuggestions([]);
    }
  };

  /**
     * 入力エリアが変更された時に発火する
     * @param suggestion - 変更された値
     * @returns void
     */
  const handleOnSuggestionsFetchRequested = useCallback(
    (suggestion: { reason: string, value: string }): void => fetchCandidates(suggestion.value)
    , []);

  /**
     * サジェストが空にされた時に発火
     * @return void
     */
  const handleOnSuggestionsClearRequested = useCallback((): void => setSuggestions([]), []);

  /**
     * サジェストを決定したときにinputに表示する内容
     * @param suggestion - 選択肢
     * @returns サジェスト
     */
  const hanldeGetSuggestionValue = useCallback((suggestion): string => suggestion.name, []);

  /**
     * サジェストの表示内容取得
     * @param suggestion - 選択肢
     * @returns サジェスト
     */
  const renderSuggestion = useCallback((suggestion): React.ReactNode => (
    <>
      <FontAwesomeIcon icon={faSearch} className="me-2" />
      {suggestion.name}
    </>
  ), []);

  /**
     * inputへのprops
     */
  const inputProps = useMemo(() => ({
    onChange: handleOnChangeCandidate,
    value: candidate,
    className: `form-control ${styles.input}`,
    placeholder: "キーワード例) 前職、将来etc..."
  }), [candidate]);

  /**
     * 追加
     * @param e - クリックイベント
     */
  const handleClick = async (e: React.MouseEvent<HTMLInputElement>): Promise<void> => {
    try {
      setCanSend(false);
      const res = await axios.post('/questions', {name: candidate, format: 'json'});
      props.onChange(res.data);
    } catch (err) {
      console.error(err);
    } finally {
      setCanSend(true);
      setCandidate('');
    }
  };

  const theme = useMemo(() => ({ container: styles.container }), []);

  return (
    <>
      <InputGroup>
        <Autosuggest id="questions"
          suggestions={suggestions}
          onSuggestionsFetchRequested={handleOnSuggestionsFetchRequested}
          onSuggestionsClearRequested={handleOnSuggestionsClearRequested}
          getSuggestionValue={hanldeGetSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={theme} />
        <Button variant="primary" onClick={handleClick} disabled={!canSend}>追加</Button>
      </InputGroup>
    </>
  );
};

export default React.memo(SearchOrCreateQuestionField);
