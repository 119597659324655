import React, {useCallback, useEffect, useMemo, useState} from "react";
import ReactAutosuggest from 'react-autosuggest';
import styles from "./Autosuggest.module.scss";

const Autosuggest = ({theme, ...props}) => {
  const injectTheme = useMemo(() => ({
    container: styles.container,
    suggestionsContainerOpen: styles.suggestionsContainerOpen,
    suggestionsList: styles.suggestionsList,
    suggestion: styles.suggestion,
    ...theme
  }), [theme]);

  return (<ReactAutosuggest theme={injectTheme} {...props} />);
};

export default React.memo(Autosuggest);
