import axios from 'axios';

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

axios.interceptors.response.use(
  response => response,
  error => {
  switch (error.response.status) {
    case 422:
    case 200:
      break;
    case 401:
      alert('認証は切れました。ログインしてください。');
      break;
    default:
      console.error(error);
      alert('通信に失敗しました。\nしばらくたってから再度お試しください。');
      break;
  }
  return Promise.reject(error);
});

export default axios;
