window.addEventListener('DOMContentLoaded', () => {
  const resize = (e: HTMLElementEvent<HTMLInputElement>, defaultRows: number): void => {
    const elem = e.currentTarget;
    const lines = (`${elem.value}\n`).match(/\n/g).length;
    elem.rows = lines <= defaultRows ? defaultRows : lines;
  };

  const addEvent = (elem: Node): void => {
    const defaultRows = elem.rows;
    elem.addEventListener('input', (e: HTMLElementEvent<HTMLInputElement>): void => resize(e, defaultRows));
    elem.dispatchEvent(new Event('input', {bubbles: true, cancelable: true}));
  };

  document.querySelectorAll('.js__resize-textarea').forEach(addEvent);
});
