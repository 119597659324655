import {loadStripe} from '@stripe/stripe-js'

const stripeTokenHandler = token => {
  // Insert the token ID into the form so it gets submitted to the server
  const form = document.getElementById('paymentForm');
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripe_token');
  hiddenInput.setAttribute('value', token.id);
  // フォームにトークンを挿入
  form.appendChild(hiddenInput);

  // Submit the form
  // フォームを送信
  const method: string = form.getAttribute('method')?.toLowerCase() || 'get';
  if (['get', 'post'].every((key: string): boolean => key !== method)) {
    Rails.fire(form, "submit")
  } else {
    form.submit();
  }
};

window.addEventListener('DOMContentLoaded', async () => {
  if (!document.getElementById('card-element')) {
    return;
  }
  const cardErrorElem = document.getElementById('card-errors');

  const stripe = await loadStripe(process.env.STRIPE_PUBLIC_KEY);
  const elements = stripe.elements();

  const style = {
    base: {
      color: '#32325d',
      lineHeight: '18px',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };

  const card = elements.create('card', {style, hidePostalCode: true});
  card.mount('#card-element');

  // エラー表示
  card.addEventListener('change', event => cardErrorElem.textContent = event.error?.message || '');

  // submit
  document.getElementById('paymentForm').addEventListener('submit', async e => {
    e.preventDefault();

    const result = await stripe.createToken(card, {name: document.querySelector('#cardName').value});
    if (result.error) {
      cardErrorElem.textContent = result.error.message;
      e.target.querySelector('[type="submit"]')?.removeAttribute("disabled");
    } else {
      stripeTokenHandler(result.token);
    }
  });
});
