window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.js__thumbnail-input').forEach(elem => {
    elem.addEventListener('change', e => {
      if (!e.target.dataset.target) return;
      const targets = document.querySelectorAll(e.target.dataset.target);
      if (e.target.files?.[0]) {
        const reader = new FileReader();
        reader.onload = event => {
          targets.forEach(target => {
            target.setAttribute('src', event.target.result);
          });
        };
        reader.readAsDataURL(e.target.files[0]);
      } else if(e.target.dataset.sample) {
        targets.forEach(target => {
          target.setAttribute('src', e.target.dataset.sample);
        });
      }
    });
  });
});
