window.addEventListener('DOMContentLoaded', () => {
  // 同意するのチェックボックス
  const consent_chk = document.querySelector(`#user_check_withdrawal`);
  // 送信ボタン
  const submit_btn = document.querySelector(`#withdrawal_submit`);

  // チェックボックスの入力イベント
  if (consent_chk) {
    consent_chk.addEventListener('change', () => {
      // チェックボックスがあれば無効化をオフ、なければオン
      if (consent_chk.checked === true) {
        submit_btn.disabled = false;
      } else {
        submit_btn.disabled = true;
      }
    });
  }
});
